import { CookiesTypes, getCS } from '../../../service/storage';
import {
    sendLiveChatCRS,
    trackCRS,
    trackCheckoutInfoCRS,
    trackPurchaseCRS,
    trackSubscribeCRS,
    trackUpadteCartCRS,
} from './requests';
import { IItem, IPurchaseItem } from './domain';
import { createCRSPurchaseItems, createWishListObject } from './creators';
import { TCRSParams } from '../affiliates';
import { IApiCartItem } from '@/entities/Cart/domain/Cart.domain';

enum EVENT {
    attractions = 'attractions',
    productDetail = 'productDetail',
    wishListAdded = 'wishListAdded',
    wishListRemoved = 'wishListRemoved',
    checkout = 'checkout',
    pageView = 'pageView',
    promoCodeRequest = 'promoCodeRequest',
    subscribed = 'subscribed',
    purchase = 'purchase',
    updateCart = 'updateCart',
}

const CHECKOUT_INFO = 2;

const getWpage = () =>
    (typeof window !== 'undefined' && window.location.pathname + window.location.search) || '';

export const trackUpdateCart = async (items: IPurchaseItem[]) => {
    return trackUpadteCartCRS(EVENT.updateCart, items);
};

export const trackAttractions = async (payload: {
    category: { id: number; slug: string; name: string } | null;
    destination: { id: number; slug: string; name: string };
    arrivalDate: number;
    departureDate: number;
}) => {
    const data = Object.assign({}, payload, {
        arrival_date: new Date(payload.arrivalDate),
        departure_date: new Date(payload.departureDate),
    });

    return trackCRS(EVENT.attractions, data);
};

export const trackProductDetail = async (payload: {
    product_id: number;
    brand: string;
    category: { id: number; slug: string; name: string };
    destination: { id: number; slug: string; name: string };
    image: string;
    name: string;
    price: number;
    price_strike_out: number | null;
}) => {
    const data = Object.assign({}, payload, { path: getWpage() });

    return trackCRS(EVENT.productDetail, data);
};

export const trackWishListAdded = async (payload: { id: number | string; slug: string }) => {
    const data = createWishListObject(payload);

    return trackCRS(EVENT.wishListAdded, data);
};

export const trackWishListRemoved = async (payload: { id: number | string; slug: string }) => {
    const data = createWishListObject(payload);

    return trackCRS(EVENT.wishListRemoved, data);
};

export const trackPurchase = async (
    grossTotal: number,
    promoDetails: { name: string; code: string },
    items: IApiCartItem[],
    orderId: string
) => {
    const crsParams = getCS(CookiesTypes.CS_CRS_UTM) as TCRSParams | undefined;
    const campaignId = (crsParams?.utm_campaign || crsParams?.campaign)?.replace('campaign_', '');

    const dataReq = {
        total: grossTotal,
        orderId,
        promoCode: promoDetails.code.toLowerCase(),
        promoName: promoDetails.name,
        ...(campaignId && { campaignId }),
        items: createCRSPurchaseItems(items),
    };

    //const uniqIds = [...new Set(Object.values(items).map(({ id }) => id))].join();

    //checkAndTrackCRSParams(uniqIds, crsParams);

    return trackPurchaseCRS(EVENT.purchase, dataReq);
};

export const trackCheckout = async (stage: 1 | 2, products: Array<IItem>) => {
    if (stage === CHECKOUT_INFO) {
        return trackCheckoutInfoCRS(EVENT.checkout, { stage, products });
    }
    return trackCRS(EVENT.checkout, { stage, products });
};

export const trackPageView = async (payload?: { path?: string; title?: string }) => {
    const title = window.document?.title;
    const path = window.location?.pathname;
    const data = {
        path: payload?.path || path,
        title: payload?.title || title,
    };

    return trackCRS(EVENT.pageView, data);
};

export const trackPromoCodeRequest = async (payload: {
    success: boolean;
    name: string;
    code: string;
}) => {
    const data = Object.assign({}, payload, {
        code: payload.code.toLowerCase(),
        path: getWpage(),
    });

    return trackCRS(EVENT.promoCodeRequest, data);
};

export const trackSubscribed = (payload: { email: string }) => {
    const data = Object.assign({}, payload, {
        path: getWpage(),
    });

    return trackSubscribeCRS(EVENT.subscribed, data, payload.email);
};

export const trackLiveChat = (attributesEmail: string, firstName: string) => {
    return sendLiveChatCRS(attributesEmail, firstName);
};
