import { TDate, useDateContext } from '@/common/app/contexts/DateContext';
import { mergeAvailability } from '@/common/app/utils/mergeAvailability';
import { IDateRange } from '@/common/domain/Date.domain';
import { IActivityWithAvailability } from '@/common/domain/Merge.domain';
import {
    IActivityItem,
    IFullList,
    TImageExcerpt,
} from '@/common/service/api/Activity/Activity.domain';
import { ICategory } from '@/common/service/api/Categories/Categories.domain';
import { IPartnersItem } from '@/common/service/api/Partners/Partners.domain';
import {
    IDestination,
    IDestinationCategory,
    IDestinationList,
} from '@/entities/Destination/domain/Destination.domain';
import { useRouter } from 'next/router';
import {
    createContext,
    memo,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { getAvailabilityShortByIds } from '@/entities/Attractions/service/ApiAttractionsPage';
import { CreateParams, splitAvailAndSoldout } from '@/entities/Attractions/service/Creators';
import {
    getSortFunction,
    SORT_OPTIONS,
    sortByBestMatch,
} from '@/entities/Attractions/service/SortFunctions';

import { getRecommends } from '@/shared/FeaturedActivities/service/ApiFeaturedActivities';
import { TCommonTypeActivityProps } from '@/shared/FeaturedActivities/domain/FeaturedActivities.domain';
import { fetchUserImagesInSearch } from '@/common/service/api/Activity/Activity';
import { checkAndSavePromo } from '@/shared/Layout/service/checkAndSavePromo';
import { useCartContext } from '.';
import { trackSortingSelected } from '@/shared/Analytics/GA4';
import { arrayHasOddItem } from '../utils/arrayHasOddItem';
import { replaceDateInPath } from '../utils/replaceDateInPath';
import { loadCookiePromocode } from '@/common/service/storage';

const TRIPS_CHUNK_COUNT = 20;

type AttractionsProviderProps = PropsWithChildren<{
    fullList?: IFullList;
    firstTenActivities?: IActivityWithAvailability[];
    currentDestination?: IDestinationList;
    currentCategory?: IDestinationCategory;
    noRecommends?: boolean;
}>;

export interface IAttractionsSearch {
    destination: IDestination;
    category?: ICategory;
    partner?: IPartnersItem;
    dateRange: IDateRange;
}

export interface IDestinationPartners {
    loading: boolean;
    items: IPartnersItem[];
    destination_id?: string;
}

type ContextProps = {
    isSoldOut: boolean;
    isLoading: boolean;
    isPartialLoading: boolean;
    isRecommendsLoading: boolean;
    isLoadingNext: boolean;
    fullList: IFullList;
    recommends: TCommonTypeActivityProps[] | null;
    firstTenActivities: IActivityWithAvailability[];
    soldOutListRecommends: TCommonTypeActivityProps[];
    showList: IActivityWithAvailability[];
    soldOutList: IActivityWithAvailability[];
    userImages: TImageExcerpt;
    hasMoreTrips: boolean;
    currentCategory?: string;
    sortOptionIndex: number;
    categoryFilterList: string[];
    initAvailability: (query: TDate) => void;
    sortList: (sortName: string, newDate?: TDate) => void;
    onScrollPage: () => void;
    setSortOptionIndex: (sortOptionIndex: number) => void;
    filterByCategory: (category_id: string) => void;
    clearAllCategoryFilters: () => void;
};

const DEFAULT_STORE: ContextProps = {
    isSoldOut: false,
    isLoading: true,
    isPartialLoading: false,
    isRecommendsLoading: false,
    isLoadingNext: false,
    firstTenActivities: [],
    recommends: null,
    soldOutListRecommends: [],
    fullList: {
        items: [],
        total: 0,
        activity_ids: '',
    },
    showList: [],
    soldOutList: [],
    userImages: {} as TImageExcerpt,
    hasMoreTrips: true,
    sortOptionIndex: 0,
    categoryFilterList: [],
    initAvailability: () => {
        return;
    },
    sortList: () => {
        return;
    },
    onScrollPage: () => {
        return;
    },
    setSortOptionIndex: () => {
        return;
    },
    filterByCategory: () => {
        return;
    },
    clearAllCategoryFilters: () => {
        return;
    },
};

const AttractionsContext = createContext<ContextProps>(DEFAULT_STORE);

const AttractionsContextProvider = ({ children, ...props }: AttractionsProviderProps) => {
    const { date } = useDateContext();
    const { runToast } = useCartContext();
    const { query, isReady, replace, asPath } = useRouter();

    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(DEFAULT_STORE.isLoading);
    const [isPartialLoading, setIsPartialLoading] = useState(DEFAULT_STORE.isPartialLoading);
    const [isRecommendsLoading, setIsRecommendsLoading] = useState(
        DEFAULT_STORE.isRecommendsLoading
    );
    const [isSoldOut, setIsSoldOut] = useState(DEFAULT_STORE.isSoldOut);
    const [firstTenActivities, setFirstTenActivities] = useState<IActivityWithAvailability[]>(
        DEFAULT_STORE.firstTenActivities
    );
    const [soldOutListRecommends, setSoldOutListRecommends] = useState<TCommonTypeActivityProps[]>(
        DEFAULT_STORE.soldOutListRecommends
    );
    const [recommends, setRecommends] = useState<TCommonTypeActivityProps[] | null>(
        DEFAULT_STORE.recommends
    );
    const [sortOptionIndex, setSortOptionIndex] = useState(DEFAULT_STORE.sortOptionIndex);
    const [fullList, setFullList] = useState<IFullList>(DEFAULT_STORE.fullList);
    const [defaultIds, setDefaultIds] = useState<string>('');
    const [showList, setShowList] = useState<IActivityWithAvailability[]>([]);
    const [soldOutList, setSoldOutList] = useState<IActivityWithAvailability[]>([]);
    const [userImages, setUserImages] = useState<TImageExcerpt>(DEFAULT_STORE.userImages);
    const [hasMoreTrips, setHasMoreTrips] = useState<boolean>(DEFAULT_STORE.hasMoreTrips);
    const [extraItem, setExtraItem] = useState<IActivityWithAvailability | undefined>();
    const [isLoadingNext, setIsLoadingNext] = useState(DEFAULT_STORE.isLoadingNext);
    const [initDate, setInitDate] = useState(date);
    const [categoryFilterList, setCategoryFilterList] = useState<string[]>(
        DEFAULT_STORE.categoryFilterList
    );

    const initAvailability = useCallback(
        async (query: TDate, newList?: IActivityItem[], promo?: string | string[]) => {
            const promocode = await checkAndSavePromo(promo, runToast);

            const [activity_ids, params] = CreateParams(newList || showList, query, promocode);

            const [newImages, availability] = await Promise.all([
                fetchUserImagesInSearch(activity_ids),
                getAvailabilityShortByIds(activity_ids, params),
            ]);

            const newActivities = mergeAvailability(newList || showList, availability);
            const { available, soldout } = splitAvailAndSoldout(newActivities);

            const listLength = newList?.length || showList.length;
            const isHasMore = (props.fullList?.items?.length || 0) > listLength;
            setHasMoreTrips(isHasMore);

            if (isHasMore) {
                setExtraItem(undefined);
                if (arrayHasOddItem(available)) {
                    const oddElement = available.pop();
                    setExtraItem(oddElement);
                }

                setShowList(available);
                setSoldOutList(soldout);
            } else {
                setShowList(available.concat(soldout));
                setSoldOutList([]);
                setExtraItem(undefined);
                if (!available.length) {
                    setIsSoldOut(true);
                    createSoldOutRecommendsData();
                }
            }

            if (newImages) setUserImages(newImages);

            setIsLoading(false);
            setIsPartialLoading(false);
            setIsInitialized(true);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props]
    );
    /* eslint-disable sonarjs/cognitive-complexity */
    const onScrollPage = useCallback(async () => {
        const startPoint = showList.length + soldOutList.length + (extraItem ? 1 : 0);
        const lastPoint = startPoint + TRIPS_CHUNK_COUNT;
        const newList = fullList.items.slice(startPoint, lastPoint);

        if (!newList.length) {
            if (soldOutList.length || extraItem) {
                const newSoldout = soldOutList.slice(0, TRIPS_CHUNK_COUNT);
                const restSoldout = soldOutList.slice(TRIPS_CHUNK_COUNT);

                setShowList((prev) => {
                    const newShowList = extraItem
                        ? prev.concat(extraItem, newSoldout)
                        : prev.concat(newSoldout);

                    const hasOdd =
                        restSoldout.length && arrayHasOddItem(newShowList)
                            ? newShowList.pop()
                            : undefined;

                    const moreTrips = !!(hasOdd || restSoldout.length);

                    setExtraItem(hasOdd);
                    setHasMoreTrips(moreTrips);
                    return newShowList;
                });
                setSoldOutList(restSoldout);
                return;
            }

            setHasMoreTrips(false);
            return;
        }

        setIsLoadingNext(true);

        const [activity_ids, params] = CreateParams(newList, date);

        const [newImages, availability] = await Promise.all([
            fetchUserImagesInSearch(activity_ids),
            getAvailabilityShortByIds(activity_ids, params),
        ]);

        const newActivities = mergeAvailability(newList, availability);
        const { available, soldout } = splitAvailAndSoldout(newActivities);

        setShowList((prev) => {
            const newShowList = extraItem ? [extraItem].concat(available) : available;

            const hasOdd = arrayHasOddItem(prev.concat(newShowList))
                ? prev.concat(newShowList).pop()
                : undefined;

            const showAndSoldout =
                prev.length + newShowList.length + soldOutList.length + soldout.length;

            if (hasOdd) {
                newShowList.pop();
            }

            if (showAndSoldout === fullList.total) {
                const freeSpace = TRIPS_CHUNK_COUNT - newShowList.length;

                const newSoldout = soldOutList.concat(soldout);
                const addSoldout = newSoldout.slice(0, freeSpace);
                const restSoldout = newSoldout.slice(freeSpace);
                const newList = hasOdd
                    ? newShowList.concat(hasOdd, addSoldout)
                    : newShowList.concat(addSoldout);

                const newOdd =
                    restSoldout.length && arrayHasOddItem(newList) ? newShowList.pop() : undefined;

                setExtraItem(newOdd);
                setSoldOutList(restSoldout);
                return prev.concat(newList);
            }
            setExtraItem(hasOdd);
            setSoldOutList((prev) => prev.concat(soldout));
            return prev.concat(newShowList);
        });
        setUserImages({ ...userImages, ...newImages });
        setIsLoadingNext(false);
    }, [showList.length, soldOutList, extraItem, fullList.items, fullList.total, date, userImages]);

    const createRecommendsData = useCallback(
        async (newDate?: TDate) => {
            if (!props.currentDestination || props.noRecommends) {
                return;
            }

            try {
                const promo = query.promo || loadCookiePromocode();
                const recommendsRes = await getRecommends({
                    destination_id: props.currentDestination.id,
                    category_id: props.currentCategory?.id,
                    ...(newDate ? newDate : date),
                    ...(promo ? { promocode: promo as string } : {}),
                });

                setRecommends(recommendsRes as TCommonTypeActivityProps[]);
                setIsRecommendsLoading(false);
            } catch (error) {
                setRecommends([]);
                setIsRecommendsLoading(false);
            }
        },
        [props.currentDestination, props.currentCategory?.id, date, query.promo, props.noRecommends]
    );

    const sortList = useCallback(
        async (sortName: string, newDate?: TDate) => {
            setIsPartialLoading(true);
            if (newDate) {
                const newPath = replaceDateInPath(asPath, newDate.from, newDate.to);

                await replace(newPath, undefined, { scroll: false });

                setInitDate(newDate);
            }

            const isDefaultSort = sortName === SORT_OPTIONS.bestMatch;
            const sortFunc = getSortFunction(sortName);

            const newFullList = isDefaultSort
                ? sortByBestMatch(fullList.items, defaultIds)
                : [...fullList.items].sort(sortFunc);

            const newFirstTenTrips = newFullList.slice(0, TRIPS_CHUNK_COUNT);

            setFullList({ ...fullList, items: newFullList });

            initAvailability(newDate || date, newFirstTenTrips);

            setIsRecommendsLoading(true);
            createRecommendsData(newDate);

            trackSortingSelected({
                value: sortName,
            });
        },
        [fullList, date, defaultIds, initAvailability, asPath, replace, createRecommendsData]
    );

    const createFirstTenActivitiesData = useCallback(async () => {
        if (!props.fullList) {
            return;
        }

        const activities = props.fullList.items.slice(0, TRIPS_CHUNK_COUNT || 12);

        setFirstTenActivities(activities);
        initAvailability(date, activities, query.promo);
    }, [date, initAvailability, props.fullList, query.promo]);

    const createSoldOutRecommendsData = useCallback(async () => {
        if (props.currentDestination?.id) {
            const recommendsRes = await getRecommends({
                destination_id: props.currentDestination.id,
                ...date,
            });

            setSoldOutListRecommends(recommendsRes as TCommonTypeActivityProps[]);
            setIsLoading(false);
        }
    }, [date, props.currentDestination]);

    const filterByCategory = useCallback(
        (category_id: string) => {
            const newIds = categoryFilterList.includes(category_id)
                ? categoryFilterList.filter((id) => id !== category_id)
                : categoryFilterList.concat(category_id);

            setCategoryFilterList(newIds);
        },
        [categoryFilterList]
    );

    const clearAllCategoryFilters = useCallback(() => {
        setCategoryFilterList([]);
    }, []);

    useEffect(() => {
        if (
            isReady &&
            props.fullList &&
            (fullList.activity_ids !== props.fullList.activity_ids ||
                ((date.from !== initDate.from || date.to !== initDate.to) && !isPartialLoading))
        ) {
            setIsLoading(true);
            setIsInitialized(false);
            setShowList([]);
            setRecommends(null);
            setSoldOutList([]);
            setIsSoldOut(false);
            setSoldOutListRecommends([]);
            setSortOptionIndex(DEFAULT_STORE.sortOptionIndex);

            if (props.fullList.items.length) {
                setHasMoreTrips(true);
            }
        }
    }, [date, fullList.activity_ids, initDate, isReady, props.fullList, isPartialLoading]);

    useEffect(() => {
        if (!props.fullList) {
            return;
        }
        if (showList.length + soldOutList.length < props.fullList.total) {
            setHasMoreTrips(true);
        } else {
            setHasMoreTrips(false);
        }
    }, [showList, props.fullList, soldOutList.length]);

    useEffect(() => {
        if (
            !showList.length &&
            isReady &&
            props.fullList &&
            date.from &&
            (fullList.activity_ids !== props.fullList.activity_ids ||
                ((date.from !== initDate.from || date.to !== initDate.to) && !isPartialLoading))
        ) {
            setInitDate(date);
            setFullList(props.fullList);
            setDefaultIds(props.fullList.activity_ids);
            createRecommendsData();
            createFirstTenActivitiesData();
            setCategoryFilterList([]);
        }
    }, [
        createFirstTenActivitiesData,
        createRecommendsData,
        date,
        fullList.activity_ids,
        initDate,
        isReady,
        props.fullList,
        showList.length,
        isPartialLoading,
    ]);

    useEffect(() => {
        if (isLoading && recommends && isInitialized) {
            setIsLoading(false);
        }
    }, [isInitialized, isLoading, recommends]);

    const contextProviderValue = useMemo(
        () => ({
            isLoading,
            isPartialLoading,
            isRecommendsLoading,
            isLoadingNext,
            isSoldOut,
            recommends,
            firstTenActivities,
            soldOutListRecommends,
            fullList,
            showList,
            soldOutList,
            userImages,
            hasMoreTrips,
            sortOptionIndex,
            currentCategory: query.category as string,
            initAvailability,
            sortList,
            onScrollPage,
            setSortOptionIndex,
            categoryFilterList,
            filterByCategory,
            clearAllCategoryFilters,
        }),
        [
            isLoading,
            isPartialLoading,
            isRecommendsLoading,
            isLoadingNext,
            isSoldOut,
            recommends,
            firstTenActivities,
            soldOutListRecommends,
            fullList,
            showList,
            soldOutList,
            userImages,
            hasMoreTrips,
            sortOptionIndex,
            query.category,
            initAvailability,
            sortList,
            onScrollPage,
            categoryFilterList,
            filterByCategory,
            clearAllCategoryFilters,
        ]
    );

    return (
        <AttractionsContext.Provider value={contextProviderValue}>
            {children}
        </AttractionsContext.Provider>
    );
};

export const AttractionsProvider = memo(AttractionsContextProvider);

export const useAttractionsContext = () => {
    const context = useContext(AttractionsContext);

    if (!context) {
        throw new Error('Context must be used within a Context Provider');
    }

    return context;
};
